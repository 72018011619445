/* .navdiv, .bio, .projectContainer{
    display: grid;
} */
/* .projectContainer{
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 2px;
    grid-row-gap: 0px;
} */

.portfolio{
    height: 650px;
    width: 100%;
    overflow:scroll;
    
}
.pseudoBody{
    position: relative;
}

.intro{
    margin-left: 20px;
    margin-right: 20px;
    font-size: 35px;
}
.navdiv{
    
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
.links{
    /* align-content: center;
    display: flex;
    justify-content: space-around; */
    grid-area: 1 / 2 / 2 / 5;
}
.links a{
    border-left: 1x solid white;
    border-right: 1px solid white;
    text-decoration: none;
    background-color: none;
    color: white;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.customBtn{
margin-right: 85%;
}
/* .links a:visited{
    text-decoration: none;
    color: White;
}
.links a:hover{
    color: black;
    background-color: white;
} */

.socials{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; /* Align items to the start of the container (upper edge) */
    align-items: center; /* Align items to the end of the container (right edge) */
    position: fixed; /* Fix the position so it stays in place while scrolling */
     /*  top: 20px; Adjust the distance from the top */
     /*  right: 10px; Adjust the distance from the right */
     margin-right: 85%;
     /* border: 1px solid red; */
     margin-top: 2%;
}

.footer{

    position: absolute;
    text-align: center;
    font-size: 200%;

    right: 50%;
    bottom: 0;
}

.intro, .bio, .footer,.mobileMenuLabel{ 
    color: white;
}

.intro{
    text-align: justify;
}

.bio{
    font-size: 200%;
    position: relative;
    text-align: left;
    margin-right: 90px;
    padding-left: 50px;
    padding-right: 10px;
    /* border: 1px red solid; */
    text-wrap: pretty;
    /* overflow: scroll; */

}
.img{
    /* -webkit-box-shadow: 6px 4px 22px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 4px 22px 0px rgba(0,0,0,0.75);
    box-shadow: 6px 4px 22px 0px rgba(0,0,0,0.75); */
    border-radius: 50%;
    width:25%;
    height: 50%;
    float: left;
    margin-left: 100px;
    margin-right: 10px;
    
    /* border: 1px solid red; */
    
}

.smallImgDiv{
    height: 400px;
    width: 400px;
}

 .sndImg{
    display: none;
}

.bioDiv{
    margin-left: 20px;
    /* border:  1px solid red; */
    padding-left: 20px;
    height: 100vh;
    overflow: scroll;
}



/* .container{
    text-align: center;
    border: none;
    padding: 1vh;
} */

.buttonNav{
   
   /* display: inline-block;  Make the button a block-level element to take up the full width available */
    /* position: absolute; */
    background-color: transparent;
    color: white;
    border-color: white;
    border-radius: 50%;
    min-height: 3vh;
    min-width: 3vw;
    font-size: 2em;
    margin: 0 0 10px 0;
    /* margin: 10px 30px 0px 0px; */
   
}

.svg, .bars{
    padding: 1vw;
    stroke-width: 20px;
    stroke: white;
    fill: transparent;
    
}




.burgerList{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.burgerList, li{
    font-size: 30px;
    margin-bottom: 50px;
    /* text-decoration: underline 1px white; */


    /*  V  Below is for testing purposes V*/
    /* border: 1px solid red; */
}


/* the css below here for the burger menu and its links are interfering with other links on the portfolio, revise names*/

.burgerList, a, a:visited{
    text-decoration:  none;
    color: white;
    
}

 .burgerListItem:hover, .burgerLink:hover, .burgerLink:visited:hover{
    color: #439f05;
    text-decoration: underline 1px #439f05;
}

.burgerLinkEmail:hover{
    color: #439f05;
}

/* .burgerList,li:hover svg{
    stroke: currentColor;
} */

.gitHubBurger, .emailBurger, .linkedBurger{
    height: 30px;
    width: 40px;
    padding-left: 10px;
    stroke: currentColor;

}



@media (min-width:684px){
    .bars{
        display: none;
    }
    .img{
        margin-left: auto;
        width: 300px;
        height: auto;
    }
}

@media (max-width:683px){
    .bars{
        /*remove display none in order for hamburger menu to show and for its rules to apply*/
        display: none;
/* 
        position: fixed;
       
        margin-left: 5%;
        margin-top: 50px; */
    }
    /* .socials{
        flex-direction: row-reverse;
        position: fixed;
        left: 35px;
        top: 40px;
        border: 1px solid red;
        margin-right: 595px;
    } */
    .socials{
        margin-right: 85%;
    }
    /* .bioDiv{
        overflow: scroll;
    } */
}

@media(max-width: 682px){
    .img{
        /* float: none;
        max-width: 100%;
        height: auto;
       */
       display: none;
    }
    /* .smallImgDiv{
        display: block;
        width: 400px;
        height: 400px;
        border: 100px solid red;
    } */
    .sndImg{
        display: block;
        max-width: 80%;
        margin-left: auto;
        border-radius: 25%;
    }
    /* .bioDiv{
        overflow: scroll;
    } */
}

@media(max-width: 540px){
    .socials{
        display: none;
    }
    .bars{
        display: flex;
        margin-bottom: 10px;
        justify-content: space-between;
        /* align-items: center; */
        margin-top: -5px;
        margin-left: -5px;
    }
    .sndImg{
        /* mess around here with the second image */
        margin: 0 auto;
    }
    /* .mobileNavMenu{
        display: flex;
        margin-bottom: 10px;
        justify-content: center;
        align-items: center;
        
    } */
    .mobileMenuLabel{
     /* display: flex;
     justify-content: start; */
     /* border: 1px solid red;  <-- for when you need it*/
     font-size: 200%;

     /*weird things happend with the labels spacing from the top when the below line is removed*/
     margin-top: 0px;
     padding: 1px;
    }
   .bioDiv{
    /* border: 1px solid; */
    height: 80vh;
   }
   .bio{
    padding: 0px;
    /* height: 100vh; */
    /* overflow: scroll; */
    /* border: 1px solid red; */
   }
}