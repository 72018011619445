*{
    font-family: 'Quicksand', sans-serif;
    overflow: hidden;
}

body{

    background: #153500;
    /* background: radial-gradient(circle, rgba(67,159,5,1) 39%, rgba(44,224,14,1) 94%, rgba(44,224,14,1) 99%); */
}

a:visited{
    text-decoration: none;
    color: White;
}
