
.project{

    /* padding: 5vh; 
    color: white;
    display: flex;
    flex-direction: column;
   */
    margin: 10px 80px 10px 80px;  

    color: #fff;
    display: flex;
    flex-direction: column;
    /* margin: 10px 80px; */
    margin-bottom: 50px;
    padding: 5vh;
    padding-bottom: 1vh;
    box-shadow: 1px 1px 0 #fff;
    
}

/* .datesLinks{
    display: flex;
    flex-direction: column;
    color: white;
} */

.projectList{
    /* border: 1px solid red; */
    /* display: flex;
    flex-direction: column;
    align-items: start; */
    /* margin-left: 1px; */
    padding: 0px;

}

.projectLink{
    /* margin:5px 0px; */
  
    /* border: 1px solid blue; */
    font-size: 25px;
    text-decoration: underline;

}

.description{
    /* padding-left: 1.5vw; */
    /* margin: 0 500px 0 0; */
    font-size: 25px;
}

.endDate{
    font-size: 25px;
}

.imgDiv{
    display:inline-block;
    /* border: 1px solid magenta; */
    
}

.photo{
    width: 25%;
    min-height: fixed;
    float: left;
    margin-right: 15px;
    border-radius: 10%;
    /* border: 1px solid orange; */
    box-shadow: 1px 1px 0px white;
    min-width: 300px;

   
}


/*wrtie code for responsive code for the portfolio*/
@media (max-width:768px){
   .photo{
    /* border: 1px solid red; */
   }
   .project{
    /* border: 1px solid red; */
    padding: 0px;
    margin-right:10px;
   }
}

@media (max-width:540px){
    .photo{
        /* border: 1px solid purple; */
        width: 20%;
        /* height: 50%; */
        min-width: 100%;
    }
    .description,.projectLink,.endDate{
        font-size: 20px;
        font-weight: 500;
    }
    .projectLink{
        /* border: 1px solid white; */
        font-size: 20px;
        /* padding-left: 40%; */
    }
    .description{
        margin-right: 0px;
    }
    .project{
        margin-left: 0px;
    }
   
}

@media(max-width:320px){
    .photo{
        /* border: 1px solid blue; */
        min-width: 100%;
        /* min-height: 50%; */
    }
    .description,.projectLink,.endDate{
        font-size: 20px;
        
    }
}


